import React from 'react';
import styles from './Box.module.css';
import logo from '../../mlec_icon.png';
import work from '../../images/a5e9008e0f6a7ccc9e641bf9bde33a5e.jpg';

const Box: React.FC = () => (
  <div className={styles.Box}>
    {/* <img className="w-14" src={logo} alt="logo"></img> */}
    <p className="text-2xl font-semibold">
      Mira Laura Enders-Comberg
    </p>
    <p>
      Kommunikationdesign & Illustration
    </p>
    <div className="pt-10">
      <p className="italic">
        coming soon ...
      </p>
    </div>
    {/* <div className="pt-2">
      <img className="w-full lg:w-2/4" src={work} alt="work"></img>
    </div> */}
  </div>
);

export default Box;
