import Impressum from 'components/Impressum/Impressum';
import Main from 'components/Main/Main';
import React from 'react';
import './App.css';
import Box from './components/Box/Box';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const Error404 = () => <h1>404 – Seite nicht gefunden</h1>;

export const API = 'http://cockpit.enderscomberg.de/api';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/impressum" component={Impressum} />
        <Route exact path="/" component={Main} />
        <Route component={Error404} />
      </Switch>
    </Router>
  );
}

export default App;
