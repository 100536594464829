import React from 'react';
import styles from './Impressum.module.css';
import { Link } from 'react-router-dom';
import Loader from 'components/util/Loader';

class Impressum extends React.Component {
 
  state = {
    content: '',
    isLoading: false,
    error: null
  };

  constructor(p) {
    super(p);

    this.load();
  }

  load() {
    this.setState({ isLoading: true });
 
    fetch(`http://cockpit.enderscomberg.com/api/singletons/get/impressum`)
      .then(r => r.json())
      .then(data => this.setState({ content: data.description, isLoading: false }));
  }

  render() {
    const { content, isLoading, error } = this.state;

    if (isLoading) {
      return <Loader></Loader>
    }

    if (error) {
      return <div>{error}</div>
    }

    if (content) {
      return (
        <div className={styles.Impressum}>
          <div className="p-6">
            <div className="underline">
              <Link to="/">Home</Link>
            </div>
            <div className="pt-6" dangerouslySetInnerHTML={{ __html: content }}></div>
            {/* <div>
              <p>
                Mira Laura Enders-Comberg
              </p>
              <p>
                Auf dem Kranz 38<br/>
                44309 Dortmund
              </p>
              <p>
                Telefon: +49 (0)231 / 334 813 28<br/>
                Telefax: +49 (0)231 / 334 813 29
              </p>
              <p>
                E-Mail: info@enderscomberg.com
              </p>
            </div> */}
          </div>
        </div>
      )
    }

    return <div>nothing</div>
  }
}

export default Impressum;
