import Box from 'components/Box/Box';
import React from 'react';
import styles from './Main.module.css';
import { Link } from 'react-router-dom';

const Main: React.FC = () => (
  <div className="text-center">
    <section className="p-6 mt-10">
      <Box></Box>
    </section>
    <footer className="p-6">
      <p className="pt-4">
        <span>➔</span> <a href="" className="underline">mira@enderscomberg.com</a>
      </p>
      <div className="underline">
       <Link clas to="/impressum">Impressum</Link>
      </div>
    </footer>
  </div>
);

export default Main;
